import React, { forwardRef } from 'react';
import styled from 'styled-components';

const Image = styled.div`
  position: relative;
  overflow: hidden;
  height: 42rem;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @media (min-width: 600px) {
    margin: 0;
    left: initial;
    right: initial;
    width: 100%;
  }

  @media (min-width: 850px) {
    height: 60rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
  }

  & + & {
    margin-top: var(--space-m);

    @media (min-width: 850px) {
      margin-top: var(--space-xl);
    }
  }
`;

const Project = forwardRef((props, ref) => {
  const project = props.src.project.document.data;

  return (
    <Image ref={ref}>
      <img
        src={project.image.url}
        alt={`${project.title}: ${project.image.alt}`}
        data-sampler={props.dataSampler}
      />
    </Image>
  );
});

export default Project;
