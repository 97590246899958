import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Intro from '../components/intro';
import OurWork from '../components/our-work';
import Services from '../components/services';
import Press from '../components/press';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Intro />
    <OurWork />
    <Services />
    <Press />
  </Layout>
);

export default IndexPage;
