import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';

import logo from '../images/ioto-logo.svg';

const Wrapper = styled.div`
  margin-bottom: 14rem;
  max-width: 92rem;

  @media (min-width: 600px) {
    padding-right: var(--space-m);
    margin-bottom: 18rem;
  }
`;

const Logo = styled.h1`
  float: left;
  margin-left: var(--space-xxxs);
  margin-right: var(--space-s);
  margin-top: var(--space-xxs);
  opacity: ${props => (props.inView ? '1' : '0')};
  transition: all 600ms var(--easeInOutCubic);

  @media (min-width: 600px) {
    margin-left: var(--space-xxs);
    margin-right: var(--space-m);
    margin-top: var(--space-xs);
  }

  span {
    position: absolute;
    left: -1000rem;
    top: auto;
    width: 0.1rem;
    height: 0.1rem;
    overflow: hidden;
  }

  img {
    width: 11.2rem;
    height: auto;
    object-fit: contain;

    @media (min-width: 600px) {
      width: 16rem;
    }
  }

  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

const IntroText = styled.div`
  p {
    font-size: 2.8rem;
    font-weight: var(--f-light);
    line-height: 1.35;
    opacity: ${props => (props.inView ? '1' : '0')};
    transition: all 600ms var(--easeInOutCubic);
    transition-delay: 300ms;

    @media (min-width: 600px) {
      line-height: 1.3;
      font-size: 4.8rem;
    }
  }
`;

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        edges {
          node {
            data {
              intro {
                html
              }
            }
          }
        }
      }
    }
  `);

  const introData = data.allPrismicHomepage.edges[0].node.data;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  return (
    <Wrapper>
      <Logo ref={ref} inView={inView}>
        <span>IOTO™</span>
        <img src={logo} alt="IOTO™ Logo" />
      </Logo>

      <IntroText
        ref={ref}
        inView={inView}
        dangerouslySetInnerHTML={{ __html: introData.intro.html }}
      />
    </Wrapper>
  );
};

export default Intro;
