import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 14rem 0 10rem;
  opacity: ${props => (props.inView ? '1' : '0')};
  transition: all 600ms var(--easeInOutCubic);

  @media (min-width: 600px) {
    margin: 20rem 0 var(--space-xxxl);
  }

  h2 {
    font-size: 2rem;
    font-weight: var(--f-light);
    line-height: 3.2rem;
    margin-bottom: var(--space-l);

    @media (min-width: 850px) {
      margin-bottom: 6rem;
      font-size: 2.8rem;
    }
  }

  li + li {
    margin-top: var(--space-xl);

    @media (min-width: 600px) {
      margin-top: 6rem;
    }
  }

  p {
    font-size: 1.4rem;
    margin-bottom: var(--space-xxs);
  }

  a {
    font-size: 1.8rem;
    line-height: 1.3;
    text-decoration: underline;
    font-weight: var(--f-light);

    @media (min-width: 600px) {
      font-size: 2.4rem;
    }
  }
`;

const Press = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        edges {
          node {
            data {
              press_title
              press {
                publisher
                link_url {
                  url
                }
                link_text
              }
            }
          }
        }
      }
    }
  `);

  const pressData = data.allPrismicHomepage.edges[0].node.data;

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <Wrapper ref={ref} inView={inView}>
      <h2>{pressData.press_title}</h2>

      <ul>
        {pressData.press.map(item => (
          <li>
            <p>{item.publisher}</p>
            <a href={item.link_url.url} target="_blank" rel="noreferrer">
              {item.link_text}
            </a>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default Press;
