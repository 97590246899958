import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Curtains, Plane } from 'curtainsjs';
import { useInView } from 'react-intersection-observer';

import Project from '../components/project';

const Canvas = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
`;

const Wrapper = styled.div`
  margin-bottom: var(--space-xxl);
  opacity: ${props => (props.inView ? '1' : '0')};
  transition: all 600ms var(--easeInOutCubic);
  transition-delay: 600ms;

  h2 {
    font-size: 2rem;
    font-weight: var(--f-light);
    line-height: 3.2rem;
    margin-bottom: var(--space-l);

    @media (min-width: 850px) {
      margin-bottom: var(--space-xl);
      font-size: 2.8rem;
    }
  }
`;

const OurWork = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepage {
        edges {
          node {
            data {
              portfolio_title
              portfolio {
                project {
                  document {
                    ... on PrismicProject {
                      id
                      data {
                        title
                        image {
                          alt
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const ourWorkData = data.allPrismicHomepage.edges[0].node.data;

  const canvas = useRef(null);
  const projectEls = useRef([]);
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  useEffect(() => {
    const curtains = new Curtains({
      container: canvas.current,
      pixelRatio: Math.min(1.5, window.devicePixelRatio), // limit pixel ratio for performance
      premultipliedAlpha: true,
    });

    curtains
      .onRender(() => {
        // update our planes deformation
        // increase/decrease the effect
        scrollEffect = curtains.lerp(scrollEffect, 0, 0.1);
      })
      .onScroll(() => {
        // get scroll deltas to apply the effect on scroll
        const delta = curtains.getScrollDeltas();

        // invert value for the effect
        delta.y = -delta.y;

        // threshold
        if (delta.y > 60) {
          delta.y = 60;
        } else if (delta.y < -60) {
          delta.y = -60;
        }

        if (Math.abs(delta.y) > Math.abs(scrollEffect)) {
          scrollEffect = curtains.lerp(scrollEffect, delta.y, 0.5);
        }
      })
      .onError(() => {
        // we will add a class to the document body to display original images
        document.body.classList.add('no-curtains', 'planes-loaded');
      })
      .onContextLost(() => {
        // on context lost, try to restore the context
        curtains.restoreContext();
      });

    // we will keep track of all our planes in an array
    const planes = [];
    let scrollEffect = 0;

    // get our planes elements
    const planeElements = projectEls.current;

    const vs = `
      precision mediump float;

      // default mandatory variables
      attribute vec3 aVertexPosition;
      attribute vec2 aTextureCoord;

      uniform mat4 uMVMatrix;
      uniform mat4 uPMatrix;

      uniform mat4 planeTextureMatrix;

      // custom variables
      varying vec3 vVertexPosition;
      varying vec2 vTextureCoord;

      uniform float uScrollEffect;
      uniform float uTime;

      void main() {
        vec3 vertexPosition = aVertexPosition;

        // cool effect on scroll
        vertexPosition.x += sin(vertexPosition.y * 5.141592) * (sin(uScrollEffect / 2500.0));
        vertexPosition.y += cos(vertexPosition.x * 6.141592) * (sin(uScrollEffect / 1500.0));
        vertexPosition.z += sin(vertexPosition.x * 3.141592) * (sin(uScrollEffect / 600.0));

        gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);

        // varyings
        vVertexPosition = vertexPosition;
        vTextureCoord = (planeTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
      }
  `;

    const fs = `
      precision mediump float;
      varying vec3 vVertexPosition;
      varying vec2 vTextureCoord;
      uniform sampler2D planeTexture;

      void main() {
        // apply our texture
        vec4 finalColor = texture2D(planeTexture, vTextureCoord);

        // fake shadows based on vertex position along Z axis
        finalColor.rgb -= clamp(-vVertexPosition.z, 0.0, 1.0);

        // fake lights based on vertex position along Z axis
        finalColor.rgb += clamp(vVertexPosition.z, 0.0, 1.0);

        // handling premultiplied alpha (useful if we were using a png with transparency)
        finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);
        gl_FragColor = finalColor;
      }
  `;

    const params = {
      vertexShader: vs,
      fragmentShader: fs,
      widthSegments: 40,
      heightSegments: 20,
      uniforms: {
        scrollEffect: {
          name: 'uScrollEffect',
          type: '1f',
          value: 0,
        },
        time: {
          name: 'uTime',
          type: '1f',
          value: 0,
        },
      },
    };

    // add our planes and handle them
    for (let i = 0; i < planeElements.length; i++) {
      const plane = new Plane(curtains, planeElements[i], params);

      planes.push(plane);

      handlePlanes(i);
    }

    // handle all the planes
    function handlePlanes(index) {
      const plane = planes[index];

      plane
        .onReady(() => {
          // once everything is ready, display everything
          if (index === planes.length - 1) {
            document.body.classList.add('planes-loaded');
          }
        })
        .onRender(() => {
          plane.uniforms.scrollEffect.value = scrollEffect;
        });
    }
  }, []);

  return (
    <Wrapper ref={ref} inView={inView}>
      <Canvas ref={canvas} />
      <h2>{ourWorkData.portfolio_title}</h2>

      {ourWorkData.portfolio.map((project, i) => (
        <Project
          ref={el => (projectEls.current[i] = el)}
          src={project}
          key={i}
          dataSampler="planeTexture"
        />
      ))}
    </Wrapper>
  );
};

export default OurWork;
